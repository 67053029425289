@tailwind base;
@tailwind components;
@tailwind utilities;

.font-montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
}

.font-montserrat-semibold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

.font-montserrat-extrabold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
}

.font-manrope {
  font-family: "Manrope", sans-serif !important;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container-news::-webkit-scrollbar {
  display: none;
}

.input-background {
  background-color: rgba(255, 255, 255, 0.136);
  backdrop-filter: blur(8px);
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
td {
  padding: 10px;
}

.input-background::placeholder {
  color: white;
}
